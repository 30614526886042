<template>
<div class="container-top">
  <div class="pc">
    <div class="banner" :class="{'bg-dark': !detail.circle}">
      <div class="container">
        <div v-if="detail.circle" class="circle" :style="{background: detail.circle}"></div>
      </div>
      <div class="banner-content">
        <div class="col-5 flex-align">
          <div>
            <div class="flex h2"><div>{{ detail.title }}</div>
              <div v-if="detail.title==='퀵빌더'" class="main weight-700 size-13" style="line-height:28px;margin-left:4px">TM</div>
            </div>
            <div class="container">
              <div class="body0-medium" style="margin-top:12px">{{ detail.subTitle }}</div>
            </div>
            <button class="button body2-medium" style="margin-top:32px;width:242px" @click="clickButton(detail.mainButton.link)"
                    :class="`${detail.mainButton.class}`">{{ detail.mainButton.name }}</button>
          </div>
        </div>
        <div class="col-9 flex" style="align-items: flex-end">
          <img :src="img(detail.img)" :alt="detail.title" style="height:440px">
        </div>
      </div>
    </div>
    <div class="container" style="padding:100px 0 0 0">
      <div class="col-12 h8 primary" style="margin-bottom:16px">{{ detail.head.primary }}</div>
      <div class="col-4" style="margin-bottom: 100px">
        <h3 class="h3 main">{{ detail.head.title }}</h3>
      </div>
      <div class="col-8">
        <div class="body1 sub2">{{ detail.head.desc }}</div>
      </div>
      <div class="col-6" v-for="(item,idx) in detail.list" :key="'item-'+idx" style="margin-bottom:100px">
        <div class="box-icon">
          <img :src="icon(item.icon)" style="width:32px;height:32px" :class="{'svg-primary': item.icon!=='draw:speed'}">
        </div>
        <div class="subtitle3 main" style="margin-top:24px">{{ item.title }}</div>
        <div class="h5 main">{{ item.title2 }}</div>
        <div class="body2 sub2" style="margin-top:24px;padding-right:60px">{{ item.desc }}</div>
        <div class="body5" style="color:#7d7d7d;margin-top:16px">{{ item.star }}</div>
      </div>
    </div>
    <div class="box-bottom">
      <div>
        <div class="container flex-center">
          <div class="subtitle3 main">{{ detail.links.title }}</div>
        </div>
        <div class="flex-center" style="margin-top:32px">
          <button class="button is-dark body2-medium" @click="routerPush(detail.links.button1.link)"
                  style="width:242px;margin-right:16px">{{ detail.links.button1.name }}</button>
          <button class="button is-gray body2-medium" @click="routerPush(detail.links.button2.link)"
                  style="width:242px;margin-left:16px">{{ detail.links.button2.name }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile">
    <div class="banner" :class="{'bg-dark': !detail.circle}">
      <div class="container">
        <div v-if="detail.circle" class="circle" :style="{background: detail.circle}"></div>
      </div>
      <div class="banner-content">
        <div class="h6 position-relative flex-center"><div>{{ detail.title }}</div>
          <div v-if="detail.title==='퀵빌더'" class="main tm">TM</div>
        </div>
        <div class="container flex-center">
          <div class="body4" style="margin-top:4px">{{ detail.subTitle }}</div>
        </div>
        <button class="button body5-medium is-primary" style="margin-top:16px;width:120px"
                @click="clickButton(detail.mainButton.link)"
                v-if="detail.mainButton.link!=='/quickbuilder'">{{ detail.mainButton.name }}</button>
      </div>
    </div>
    <div style="padding:32px 16px">
      <div class="body6-bold primary">{{ detail.head.primary }}</div>
      <div class="h7 main" style="margin:4px 0 12px 0">{{ detail.head.title }}</div>
      <div class="body4 sub2">{{ detail.head.desc }}</div>
      <div style="background-color: #e3e3e3;display: flex;align-items: flex-end;margin:12px 0 8px 0">
        <img :src="img(detail.tablet_img)" :alt="detail.title">
      </div>

      <div class="list-info-wrapper">
        <div v-for="(item,idx) in detail.list" :key="'item-'+idx" style="margin-top:32px">
          <div class="box-icon">
            <img :src="icon(item.icon)" :class="{'svg-primary': item.icon!=='draw:speed'}">
          </div>
          <div class="subtitle4 main" style="margin-top:16px">{{ item.title }}</div>
          <div class="h7 main">{{ item.title2 }}</div>
          <div class="body4 sub2" style="margin-top:8px">{{ item.desc }}</div>
          <div class="body7" style="color:#7d7d7d;margin-top:8px">{{ item.star }}</div>
        </div>
      </div>
    </div>
    <div class="box-bottom">
      <div style="width:100%">
        <div class="container">
          <div class="body2 main">{{ detail.links.title }}</div>
        </div>
        <div class="flex-center" style="margin-top:32px">
          <button class="button body5-medium is-dark" style="width:242px;margin-right:6px" @click="clickButton(detail.links.button1.link)">{{ detail.links.button1.name }}</button>
          <button class="button body5-medium is-gray" style="width:242px;margin-left:6px" @click="routerPush(detail.links.button2.link)">{{ detail.links.button2.name }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    name: "Info",
    created() {
      this.detail = this.list[this.$route.query.id];
    },
    watch: {
      $route() {
        this.detail = this.list[this.$route.query.id];
      }
    },
    data() {
      return {
        detail: undefined,
        list:[
          {
            circle: '#17FF90',
            mainButton: {
              name: '신청하기',
              link: '/service_inquiry_reg',
              class: 'is-dark',
            },
            title: '주문 제작',
            subTitle: 'A to Z, 나만을 위한 VIP 솔루션',
            img: 'img_page_order_make',
            tablet_img: 'img_page_order_make_tablet',
            head: {
              primary: 'Customize',
              title: '최적화 된 맞춤제작\n서비스가 필요하다면',
              desc: '나만을 위한 고도화된 서비스 개설을 원할 때, 담당자와의 1:1 문의 창구로 서비스가 완벽해질 때까지 고객 니즈에 맞춘 VIP 솔루션을 제공합니다.'
            },
            list: [
              {
                icon: 'u_file-edit-alt',
                title: '서비스 형태 상관 없이',
                title2: '고객 맞춤으로',
                desc: '고객의 사업 모델에 필요한 기능을 맞춰드립니다. \n' +
                  '런치팩이 개발한 플러그인과 기능들을 이용해 고객의 요구에 \n' +
                  '따라 얼마든지 맞춤 제작 가능합니다.'
              },
              {
                icon: 'u_comment-alt-check',
                title: '부가 서비스 신청 없이도',
                title2: '1:1 문의 대응',
                desc: '별도로 가입하지 않아도 런치팩 케어서비스를 제공합니다. \n' +
                  '백오피스에 설치되는 1:1 문의 창구를 이용해 운영 중 발생하는 문제를 \n' +
                  '자유롭게, 보다 더 편리하게 해결하세요.',
                star: '*퀵빌더와 디자인 마켓은 케어서비스 가입을 통해 \n' +
                  '1:1 문의 창구를 개설할 수 있습니다.'
              },
              {
                icon: 'u_expand-from-corner',
                title: '사업이 커져도',
                title2: '서비스 고도화가 용이하니까',
                desc: '고객 맞춤형으로 제작된 서비스는 이후 사업 확장에 따른 \n' +
                  '서비스 고도화가 얼마든지 가능합니다. 새로 구상한 상품을 서비스에 \n' +
                  '추가함으로써 비즈니스를 키워가보세요.'
              }
            ],
            links: {
              title: '주문 제작을 이용한 서비스 런칭을 원한다면',
              button1: {
                name: '견적 문의 하기',
                link: '/service_inquiry_reg'
              },
              button2: {
                name: '가이드 바로 가기',
                link: '/service_inquiry_guide'
              },
            }
          },
          {
            circle: '#7B61FF',
            title: '디자인 마켓',
            subTitle: '내 서비스에 맞는 디자인을 마켓에서 골라보자',
            mainButton: {
              name: '바로가기',
              class: 'is-dark',
              link: '/store'
            },
            img: 'img_page_design_market',
            tablet_img: 'img_page_design_market_tablet',
            head: {
              primary: 'Design Market',
              title: '합리적인 가격에\n' +
                '다양한 디자인을 원한다면',
              desc: '전문가가 제작한 디자인 스킨을 구매해 원하는 페이지를 수정 개발할 수 있습니다. \n' +
                '합리적인 가격에 보장된 퀄리티의 서비스를 런칭할 수 있습니다.'
            },
            list: [
              {
                icon: 'u:chart-down',
                title: '준비가 완료된 런치팩 서버',
                title2: '짧아진 개발 기간',
                desc: '사업 모델에 적합한 디자인 수정까지 투입되는 리소스의 양은 예비창업자를 주저하게 만드는 큰 장애물입니다. 런치팩은 준비가 완료된 클라우드 기반의 서버로 개발 기간을 6개월에서 약 1개월로 줄였습니다.'
              },
              {
                icon: 'fi:edit-3',
                title: '내 서비스에 맞춘',
                title2: '수정 개발',
                desc: '높은 가격과 긴 개발 기간에도 불구하고 외주 개발을 이용하는 이유는 수정개발의 용이성 때문이었습니다. 디자인 마켓을 이용하면 서비스 개발 전은 물론, 런칭 후에도. 고객이 원한다면 언제든지 페이지 수정이 가능합니다.',
              },
              {
                icon: 'u:palette',
                title: '디자이너가 없어도',
                title2: '디자인 마켓이 있으니까',
                desc: '디자인 마켓의 상품들은 전문 디자이너가 제작했습니다. 마켓에서 원하는 디자인 스킨을 골라 원하는 부분만 수정하기 때문에 기획 단계의 시행착오를 줄일 수 있습니다. '
              }
            ],
            links: {
              title: '디자인 마켓을 이용한 서비스 런칭을 원한다면',
              button1: {
                name: '디자인 마켓 바로 가기 ',
                link: '/store'
              },
              button2: {
                name: '가이드 바로 가기',
                link: '/guide?id=0'
              },
            }
          },
          {
            circle: '#FF6600',
            title: '퀵빌더',
            subTitle: '블럭들을 조합해서 1시간 만에 런칭',
            img: 'img_page_quick',
            tablet_img: 'img_page_quick_tablet',
            head: {
              primary: 'Quick builder',
              title: '적은 예산으로 \n' +
                '빠른 런칭을 원한다면',
              desc: '런치팩이 개발한 퀵빌더 툴을 이용해 나의 사업 모델에 맞는 블럭들을 조합합니다. \n' +
                '부가 서비스 선택 후 결제 하면 서비스 개설 완료!'
            },
            mainButton: {
              name: '바로가기',
              class: 'is-dark',
              link: '/quickbuilder'
            },
            list: [
              {
                icon: 'u:bolt',
                title: '이보다 더 빠를 순 없다',
                title2: '한 시간 만에 런칭',
                desc: '플랫폼 개발의 핵심 부분만을 모았습니다. 불필요한 과정들을 생략한 생산성 높은 개발 툴을 이용해보세요. 아이디어만 있다면 블럭 조합, 결제, 서비스 런칭까지 한 시간 만에 완료할 수 있습니다!'
              },
              {
                icon: 'u:coins',
                title: '초기 투자 리스크 걱정 없는',
                title2: '합리적인 가격',
                desc: '높은 외주 개발 비용에 망설였던 플랫폼 서비스 창업을 시작할 좋은 기회입니다! 플랫폼 바로 개설은 시장 반응 확인, MVP 서비스 런칭을 원하는 창업자분들에게도 추천드리는 상품입니다.  ',
              },
              {
                icon: 'fi:code',
                title: '개발자가 없어도',
                title2: '퀵빌더가 있으니까',
                desc: '퀵빌더는 포토샵, 개발 언어 없이도 누구나 쉽게 UI를 디자인할 수 있는 개발툴입니다. 기능과 디자인 별로 준비된 블럭들을 조합해서 나만의 서비스를 손쉽게 만들어 보세요! '
              }
            ],
            links: {
              title: '퀵빌더를 이용한 서비스 런칭을 원한다면',
              button1: {
                name: '바로 가기',
                link: '/quickbuilder'
              },
              button2: {
                name: '가이드 바로 가기',
                link: '/guide?id=1'
              },
            }
          },
          {
            title: '플랫폼 운영 솔루션 API',
            subTitle: '플랫폼 운영에 필요한 관리자 사이트+백엔드\n' +
                '월 30만원 부터~',
            img: 'img_page_api',
            tablet_img: 'img_page_api_tablet',
            head: {
              primary: 'Admin',
              title: '서비스 관리자 \n' +
                '사이트가 필요하다면',
              desc: '프론트엔드의 소스코드만 개발해도, 플랫폼파이가 제공하는 관리자 사이트로\n' +
                '원활한 사업운영이 가능합니다.'
            },
            mainButton: {
              name: '신청하기',
              class: 'is-primary',
              link: '/solution_api_reg'
            },
            list: [
              {
                icon: 'u:window',
                title: '필요한 기능은 모두 담은',
                title2: '플랫폼파이의 관리자 사이트',
                desc: '플랫폼 사업 운영에 필요한 기능들을 담은 관리자 사이트를 제공합니다. 개발이 완료된 백오피스를 통해 프론트엔드 준비만으로 플랫폼 사업을 시작할 수 있습니다!'
              },
              {
                icon: 'draw:speed',
                title: '준비가 완료된 관리자 사이트로',
                title2: '개발기간 단축',
                desc: '주문 전환 방식을 선택하면 필요한 기능들이 포함된 관리자 사이트가 최대 한 시간 이내에 바로 개설됩니다. 백오피스 개발 과정을 생략함으로써 개발기간을 절반으로 단축할 수 있습니다. ',
              },
              {
                icon: 'u:server-network-alt',
                title: '안정적인 운영이 가능한',
                title2: 'AWS 기반의 플랫폼파이 서버',
                desc: '플랫폼파이의 모든 상품은 AWS (Amazon Web Service) 기반의 플랫폼파이 서버로 운영합니다. 고객과 파트너 모두가 안심할 수 있는 안정적인 서비스 운영이 가능합니다. '
              }
            ],
            links: {
              title: 'Solution API를 이용한 서비스 런칭을 원한다면 \n' +
                '월 30만원 부터~',
              button1: {
                name: '견적 문의 하기',
                link: '/solution_api_reg'
              },
              button2: {
                name: '가이드 바로 가기',
                link: '/api_guide'
              },
            }
          }
        ]
      }
    },
    methods: {
      clickButton(link) {
        if(link === '/quickbuilder' && this.isMobile) {
          this.toast('PC에서만 제공되는 서비스입니다');
        } else {
          this.routerPush(link);
        }
      },
      img(img) {
        return `/static/img/guide/${img}.png`;
      },
      icon(img) {
        return `/static/icon/${img.replace(':','_')}.svg`;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .container-top
    padding-bottom 0 !important
  .banner
    background-color $gray4
    height 440px
    position relative
    overflow hidden
    color $main
  .banner-content
    position absolute
    width $page-width
    left 50%
    transform translateX(-50%)
    display flex
    height 100%
    z-index 1
  .container
    display flex
    flex-wrap wrap
    word-break keep-all
    white-space pre-line

  .box-icon
    background-color $primary-light2
    border-radius 20px
    width 80px
    height 80px
    display flex
    align-items center
    justify-content center

  .box-bottom
    background-color $gray4
    padding 72px 0
    text-align center
    display flex
    align-items center
    justify-content center

  .circle
    opacity 0.15
    filter blur(300px)
    -webkit-backdrop-filter blur(300px)
    backdrop-filter blur(300px)
    position absolute
    top -50px
    left -603px
    width $page-width
    height $page-width

  .bg-dark
    background linear-gradient(132.62deg, #242428 34.49%, #4F4F4F 88.8%)
    color white

  @media (max-width:1024px)
    .banner
      height auto
      padding 28px 0
    .banner-content
      position relative
      transform none
      left 0
      display block
      width 100%
      text-align center
    .circle
      width 370px
      height 370px
      top -44px
      left -50px
    .box-icon
      width 48px
      height 48px
      border-radius 12px
      img
        width 20px
        height 20px
    .box-bottom
      padding 40px 32px
    .container-top
      padding-bottom 0 !important

    .tm
      line-height 13px
      margin-left 4px
      position absolute
      left calc(50% + 30px)
      top 2px
      font-size 10px

    .list-info-wrapper
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 0 42px
  @media (max-width:640px)
    .list-info-wrapper
      display block
</style>
